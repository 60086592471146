import React, { useEffect, useState } from 'react';
import { documentSnapshotToObject } from '../utils/firestore';
import useFirebase from './useFirebase';

import { User } from '../types/account';

type Hook = [boolean, User | null];

export const UserContext = React.createContext<User | null>(null);

export function getUser(): Hook {
  const firebase = useFirebase();

  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!firebase) return () => null;

    let onSnapshotUnsubscribe: Function = () => null;
    let onAuthStateChangedUnsubscribe: Function = () => null;

    onAuthStateChangedUnsubscribe = firebase.auth().onAuthStateChanged(async change => {
      if (change) {
        onSnapshotUnsubscribe = firebase
          .firestore()
          .collection('users')
          .doc(change.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              setUser(documentSnapshotToObject(doc));
              if (loading) setLoading(false);
            }
          });
      } else {
        setUser(null);
        if (loading) setLoading(false);
      }
    });

    return () => {
      onSnapshotUnsubscribe();
      onAuthStateChangedUnsubscribe();
    };
  }, [firebase]);

  return [loading, user];
}
