import React, { createRef, useEffect } from 'react';
import lottie from 'lottie-web';
import { navigate } from '@reach/router';
import { Button, Mask } from '@invertase/ui';

import Container from './Container';
import { getUser } from '../hooks/useUser';
import Link from './Link';
import Search from './Search';

const menu = {
  '/react-native/docs/overview': 'Documentation',
  '/react-native/reference': 'Reference',
};

interface Props {
  search?: boolean;
}

function Header({ search = true }: Props) {
  const [loading, user] = getUser();
  const [showMenu, setShowMenu] = React.useState<boolean>(false);

  return (
    <>
      <header className="fixed inset-x-0 top-0 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-800 z-30">
        <Container>
          <div className="grid grid-cols-12 gap-1 items-center h-16">
            <div className="col-span-1 md:col-span-2" id="logo-container">
              <Link to="/" className="flex items-center pl-1">
                <AnimatedLogo parentId="logo-container" />
                <div className="hidden md:block pl-4 font-thin tracking-wider text-xl text-purple-800 hover:text-purple-500 dark:text-white dark-hover:text-gray-400">
                  Notifee
                </div>
              </Link>
            </div>
            <div className="hidden md:block col-span-6 md:col-span-5 pl-12">
              {search && <Search />}
            </div>
            <div className="col-span-11 md:col-span-5 pr-1">
              <ul className="w-full flex items-center justify-end font-thin text-base text-purple-800 dark:text-white">
                <li className="ml-6">
                  <Link to="/react-native/docs/overview" className="hover:underline">
                    <span>
                      Doc<span className="hidden md:inline">umentation</span>
                      <span className="md:hidden">s</span>
                    </span>
                  </Link>
                </li>
                <li className="ml-6">
                  <Link to="/account" className="hover:underline">
                    My Account
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </header>
      <Mask visible={showMenu} onClose={() => setShowMenu(false)} className="mt-16">
        <div className="w-3/4 md:w-1/2 bg-white dark:bg-gray-800 fixed inset-y-0 right-0 mt-16 z-50 border-t dark:border-gray-700 shadow-lg">
          <ul>
            {Object.entries(menu).map(([to, title]) => (
              <li key={to}>
                <Link
                  to={to}
                  className="flex items-center justify-center py-4 border-b dark:border-gray-700 text-gray-700 dark:text-white"
                >
                  {`${title} »`}
                </Link>
              </li>
            ))}
            <li>
              {!loading && !user && (
                <Link
                  to="/login"
                  className="flex items-center justify-center py-4 border-b dark:border-gray-700 text-gray-700 dark:text-white"
                >
                  Login »
                </Link>
              )}
              {!loading && !!user && (
                <Link
                  to="/login"
                  className="flex items-center justify-center py-4 border-b dark:border-gray-700 text-gray-700 dark:text-white"
                >
                  {user.email || 'Account »'}
                </Link>
              )}
            </li>
          </ul>
        </div>
      </Mask>
    </>
  );
}

interface AnimatedLogoProps {
  parentId?: string;
}

function AnimatedLogo({ parentId }: AnimatedLogoProps) {
  const logoRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (!logoRef || !logoRef.current) {
      return;
    }

    let el = logoRef.current;

    if (parentId) {
      const parent = document.getElementById(parentId);
      if (parent) {
        // @ts-ignore
        el = parent;
      }
    }

    const animation = lottie.loadAnimation({
      container: logoRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      animationData: require('../../resources/lottie-logo-data.json'),
    });

    el.addEventListener('mouseenter', () => animation.play());
    el.addEventListener('mouseleave', () => animation.pause());
  }, [parentId]);

  return <div key="animated-logo" ref={logoRef} className="w-8 h-8" />;
}

export default Header;
