import { useEffect, useState } from 'react';
import * as firebase from 'firebase/app';
import getFirebase from '../firebase';

type Firebase = firebase.app.App | null;

export default function useFirebase(): Firebase {
  const [instance, setInstance] = useState<Firebase>(getFirebase());

  useEffect(() => {
    // If the instance is already available, avoid multiple set states
    // as Firebase isn't easily comparable
    if (!instance) {
      setInstance(getFirebase());
    }
  }, [instance]);

  return instance;
}
