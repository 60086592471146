import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyBfVrebr5F5Z8uhTAe8oJWQNumKNlK1v8s',
  authDomain: 'notifee-d4f5a.firebaseapp.com',
  databaseURL: 'https://notifee-d4f5a.firebaseio.com',
  projectId: 'notifee-d4f5a',
  storageBucket: 'notifee-d4f5a.appspot.com',
  messagingSenderId: '25016519482',
  appId: '1:25016519482:web:e4599d9cb07e221bc69e03',
  measurementId: 'G-05GEPHWN4K',
};

let instance: firebase.app.App | null = null;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance || firebase.apps.length) {
      return instance || firebase.apps[0];
    }
    instance = firebase.initializeApp(config);
    return instance;
  }

  return null;
}
