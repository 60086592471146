import * as firebase from 'firebase/app';
import dayjs from 'dayjs';
import { Roles } from '../types/account';

export function querySnapshotToArray<T>(querySnapshot: firebase.firestore.QuerySnapshot): T[] {
  const array: any[] = [];

  if (querySnapshot.empty) {
    return array;
  }

  if (querySnapshot.docs.length) {
    querySnapshot.docs.forEach(docSnapshot => {
      array.push({
        ...docSnapshot.data(),
        id: docSnapshot.id,
      });
    });
  }

  return array;
}

export function documentSnapshotToObject<T>(
  documentSnapshot: firebase.firestore.DocumentSnapshot,
): T {
  const out: any = {
    ...documentSnapshot.data(),
    id: documentSnapshot.id,
  };

  return out;
}

export function timestampToDate(timestamp: firebase.firestore.Timestamp, time?: boolean) {
  return dayjs(timestamp.toDate()).format(`DD/MM/YYYY${time ? ' HH:mm' : ''}`);
}

export function roleToString(role: Roles) {
  if (role === 5) return 'user';
  if (role === 10) return 'admin';
  return 'unknown';
}
