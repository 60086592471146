import React from 'react';
import { Helmet } from 'react-helmet';
import { Algolia, icons } from '@invertase/ui';

const { Search: SearchIcon } = icons;

function Search() {
  return (
    <>
      <Algolia
        apiKey="2ead9731074e69f769562f617c15ba59"
        indexName="react-native-notifications"
        id="algolia-search"
      >
        <div className="w-full relative">
          <SearchIcon
            className="absolute text-gray-600 z-10"
            size={17}
            style={{ top: 13, left: 13 }}
          />
          <input
            type="text"
            id="algolia-search"
            className="w-full border dark:border-gray-700 rounded-lg bg-gray-200 dark:bg-gray-800 focus:bg-gray-100 focus:outline-none py-2 px-4 placeholder-gray-500 pl-10"
            placeholder={`Search the docs`}
          />
        </div>
      </Algolia>
    </>
  );
}

export default Search;
